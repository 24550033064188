import cn from 'classnames';
import {
  richTextSerializerHeadingStyleAlternative,
  richTextSerializer,
} from '@ee-monorepo/prismic/shared/serializers';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';

export interface FullBgBlockStatisticsProps extends PrismicFields.SliceBase {
  slice: {
    primary?: {
      main_heading?: PrismicFields.RichTextField;
      section_heading_alignment?: string;
    };
    items?: [
      {
        description?: PrismicFields.RichTextField;
        title?: PrismicFields.RichTextField;
      }
    ];
  };
}
export function FullBgBlockStatistics({
  index,
  slice: {
    primary: { main_heading = [], section_heading_alignment } = {},
    items = [{}],
  },
}: FullBgBlockStatisticsProps) {
  let textAlignment: string;
  if (section_heading_alignment !== null) {
    textAlignment = 'text-' + section_heading_alignment;
  }
  return (
    <div
      data-testid="full-bg-block-statistics"
      data-slice-index={index}
      className="bg-SnapDarkBlue"
    >
      {/* Main Heading */}
      {prismicH.isFilled.richText(main_heading) && (
        <div className="snap-container md:pt-12 sm:pt-9">
          <div className="snap-row">
            <div
              className="w-full text-center text-SnapLightBlue"
              data-testid="full-bg-block-heading"
            >
              <PrismicRichText
                field={main_heading}
                components={richTextSerializerHeadingStyleAlternative}
              />
            </div>
          </div>
        </div>
      )}
      {/* Sections */}
      <div className="snap-container py-14 md:py-20">
        <section className={cn('row', textAlignment)}>
          {items?.map((item, index) => (
            <div className="xs:w-full sm:w-full md:w-1/3" key={index}>
              <div
                className="text-SnapYellow mb-2 md:[&>h2]:mb-1"
                data-testid="full-bg-block-statistics-title"
              >
                <PrismicRichText
                  field={item.title}
                  components={richTextSerializerHeadingStyleAlternative}
                />
              </div>
              <div
                className="text-SnapWhite mb-8 md:mb-2"
                data-testid="full-bg-block-statistics-description"
              >
                <PrismicRichText
                  field={item.description}
                  components={richTextSerializer}
                />
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}
